













































import { Component, Ref, Vue } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import couponService from "@/service/couponService";
import CouponEditDialog from "@/components/organisms/coupon/CouponEditDialog.vue";
import CouponDeleteDialog from "@/components/organisms/coupon/CouponDeleteDialog.vue";
import { Coupon } from "@/graphql/client";

@Component({ components: { CouponEditDialog, CouponDeleteDialog } })
export default class CouponList extends Vue {
  @Ref() readonly editDialog!: CouponEditDialog;
  @Ref() readonly deleteDialog!: CouponDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: Coupon[] = [];
  headers = [
    {
      text: "タイトル",
      value: "title",
      width: "20%",
    },
    {
      text: "スラッグ名称",
      value: "slugName",
      width: "15%",
    },
    {
      text: "説明",
      value: "description",
    },
    {
      text: "額面",
      value: "amount",
      width: "10%",
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  loadingDataGrid = false;

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    couponService.allCoupons().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }

  /**
   * クーポン編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(couponService.defaultCoupon);
  }

  /**
   * クーポン編集ダイアログを表示します.
   */
  public openEditDialog(item: Coupon): void {
    this.editDialog.open(item);
  }

  /**
   * クーポン編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: Coupon): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("クーポンを更新しました！");
  }

  /**
   * クーポン編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: Coupon): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("クーポンを登録しました！");
  }

  /**
   * クーポン削除ダイアログを表示します.
   */
  public openDeleteDialog(item: Coupon): void {
    this.deleteDialog.open(item);
  }

  /**
   * クーポン削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: Coupon): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("クーポンを削除しました！");
  }
}
