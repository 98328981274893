










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import CouponList from "@/components/organisms/coupon/CouponList.vue";

export default {
  components: {
    SubPageTemplate,
    CouponList,
  },
};
