
import couponService from "@/service/couponService";
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, numeric, helpers } from "vuelidate/lib/validators";

const validSlugNameFormat = (value: string, parentVm: Vue): boolean => {
  const slugNameValue = helpers.ref("slugName", this, parentVm);
  var reg = new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/);
  return reg.test(slugNameValue);
};

const isUniqueSlugName = (value: string, parentVm: Vue): boolean => {
  const slugNameValue = helpers.ref("slugName", this, parentVm);
  couponService.getCouponBySlugName(slugNameValue);
  return true;
};

@Component
export default class MixinCouponEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //名称
      title: { required },
      //スラッグ名称
      slugName: {
        required,
        validSlugNameFormat: validSlugNameFormat,
        isUniqueSlugName: isUniqueSlugName,
      },
      //額面
      amount: { required, numeric },
      //付与に必要な購入金額
      minPriceToGain: { required, numeric },
      //仕様に必要な購入金額
      minPriceToUse: { required, numeric },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * 名称の入力エラーメッセージを返します.
   */
  get titleErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.title?.$dirty) return errors;
    !this.$v.item.title?.required && errors.push("名称を入力してください.");
    return errors;
  }

  /**
   * スラッグ名称の入力エラーメッセージを返します.
   */
  get slugNameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.slugName?.$dirty) return errors;
    !this.$v.item.slugName?.required && errors.push("スラッグ名称を入力してください.");
    !this.$v.item.slugName?.validSlugNameFormat && errors.push("スラッグ名称をは半角英数字記号で入力してください.");
    !this.$v.item.slugName?.isUniqueSlugName && errors.push("スラッグ名称をは半角英数字記号で入力してください.");
    return errors;
  }

  /**
   * 額面の入力エラーメッセージを返します.
   */
  get amountErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.amount?.$dirty) return errors;
    !this.$v.item.amount?.required && errors.push("額面を入力してください.");
    !this.$v.item.amount?.numeric && errors.push("数値を入力してください.");
    return errors;
  }

  /*
   * 付与に必要な購入金額の入力エラーメッセージを返します.
   */
  get minPriceToGainErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.minPriceToGain?.$dirty) return errors;
    !this.$v.item.minPriceToGain?.required && errors.push("金額を入力してください.");
    !this.$v.item.minPriceToGain?.numeric && errors.push("数値を入力してください.");
    return errors;
  }

  /*
   * 使用に必要な購入金額の入力エラーメッセージを返します.
   */
  get minPriceToUseErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.minPriceToUse?.$dirty) return errors;
    !this.$v.item.minPriceToUse?.required && errors.push("金額を入力してください.");
    !this.$v.item.minPriceToUse?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
}
