import { Coupon } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * クーポンに関する機能を提供します.
 */
class CouponService {
  /**
   * 初期状態のクーポン
   */
  public get defaultCoupon(): Coupon {
    return {
      guid: "",
      title: "",
      amount: 0,
      slugName: "",
      description: "",
      minPriceToGain: 0,
      minPriceToUse: 0,
      createdAt: "",
      updatedAt: "",
      purpose: undefined,
    } as Coupon;
  }


  /**
   * すべてのクーポンを取得して返します.
   * @returns すべてのクーポン
   */
  public async allCoupons() {
    const response = await sdk.coupons();
    if (response?.coupons) {
      return response.coupons as Coupon[];
    }
    return [] as Coupon[];
  }

  /**
   * 指定したGUIDに一致するクーポンを取得して返します.
   * @param guid クーポンのGUID
   * @returns クーポン
   */
  public async getCoupon(guid: string) {
    const response = await sdk.coupon({
      key: {
        guid: guid,
      },
    });
    return response.coupon as Coupon;
  }

  /**
   * 指定したslugNameに一致するクーポンを取得して返します.
   * @param slugName クーポンのslugName
   * @returns クーポン
   */
  public async getCouponBySlugName(slugName: string) {
    const response = await sdk.coupon({
      key: {
        slugName: slugName,
      },
    });
    return response.coupon as Coupon;
  }

  /**
   * クーポンを登録します.（IDは自動採番）
   * @param input クーポンの登録情報
   * @returns 登録完了後のクーポン
   */
  public async createCoupon(input: Coupon) {
    try {
      const response = await sdk.createCoupon({
        createCouponInput: {
          title: input.title,
          slugName: input.slugName,
          amount: input.amount,
          description: input.description,
          minPriceToGain: input.minPriceToGain,
          minPriceToUse: input.minPriceToUse,
        },
      });

      if (response?.createCoupon) {
        return response.createCoupon;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * クーポンを更新します.
   * @param input クーポンの更新情報
   * @returns 更新完了後のクーポン
   */
  public async updateCoupon(input: Coupon) {
    try {
      const response = await sdk.updateCoupon({
        updateCouponInput: {
          guid: input.guid,
          title: input.title,
          slugName: input.slugName,
          amount: input.amount,
          description: input.description,
          minPriceToGain: input.minPriceToGain,
          minPriceToUse: input.minPriceToUse,
        },
      });

      if (response?.updateCoupon) {
        return response.updateCoupon;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * クーポンを物理削除します.
   * @param input クーポンの削除情報
   * @returns 削除完了後のクーポン
   */
  public async deleteCoupon(input: Coupon) {
    try {
      const response = await sdk.deleteCoupon({
        deleteCouponInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteCoupon) {
        return response.deleteCoupon;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new CouponService();
